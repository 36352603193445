<template>
    <DnSlider
        class="base-slider"
        :controls-enabled="true"
    >
        <!-- Pass down whatever slots are given to this component-->
        <template
            v-for="name in Object.keys($slots)"
            v-slot:[name]="scope"
        >
            <slot
                :name="name"
                v-bind="scope || {}"
            />
        </template>

        <template v-slot:controlRight="{ scrollRight, isVisible }">
            <transition name="fade">
                <div
                    v-if="isVisible"
                    class="slider__control-right"
                >
                    <BaseButton
                        class="base-button--quaternary"
                        title="Scroll naar rechts"
                        icon="arrow-right"
                        icon-position="center"
                        @click="scrollRight"
                    />
                </div>
            </transition>
        </template>

        <template v-slot:controlLeft="{ scrollLeft, isVisible }">
            <transition name="fade">
                <div
                    v-if="isVisible"
                    class="slider__control-left"
                >
                    <BaseButton
                        class="base-button--quaternary"
                        title="Scroll naar links"
                        icon="arrow-left"
                        icon-position="center"
                        @click="scrollLeft"
                    />
                </div>
            </transition>
        </template>
    </DnSlider>
</template>

<script setup>
import DnSlider from '@digitalnatives/slider';
import BaseButton from '~/components/BaseButton/BaseButton.vue';
</script>

<style src="./BaseSlider.less" lang="less"></style>
